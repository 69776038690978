<template>
  <div>
    <div>
      <!-- <div class="small-top-nav">
          <div class="top-nav">
            <p>{{ currentPath }}</p>
            <p v-if="firstname != '' && lastname != ''">Hello {{ firstname }}</p>
          </div>
        </div> -->
      <SmallNav />

      <div class="wallet">
        <div class="container-fluid">
          <div class="wallet_text_holder">
            <h4 class="your_wallet">
              <img src="../assets/images/safe.svg" alt="safe" />
              <span>SAFE</span>
            </h4>
            <div class="projects_link">
              <router-link to="/dashboard/safeprojects">
                <h6 class="btn">
                  <img src="../assets/images/house-safe.svg" alt="projects" />
                  Projects
                </h6>
              </router-link>
            </div>
          </div>

          <div v-if="pageLoader">
            <PageLoader></PageLoader>
          </div>
          <div v-else>
            <div v-if="registedForSafe === false">
              <div class="safe_header">
                <div class="">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="dream_start">
                        <h6>Dreams are Possible</h6>
                        <div>
                          <h1>JUST</h1>
                          <h1>START <span>!</span></h1>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <p class="posibility_hints">
                        SAFE is a product from Shelta that seeks to solve the
                        problems associated with payment of rent and funding for
                        other property related projects (e.g., property
                        acquisition, mortgage, etc) by leveraging on regulated
                        institutional services/infrastructure. We seek to help
                        renters and aspiring property owners meet their shelter
                        goals by creating easy funding options and also bridge
                        funding gaps when the need arises. This enables users
                        pay their rents and other projects both in advance and
                        in arrears at no extra cost.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="safe_options">
                <div class="container-fluid">
                  <div class="row select-safe-row">
                    <div class="col-md-6">
                      <div class="yellow_safe">
                        <img src="../assets/images/safe2.svg" alt="safe" />
                        <p>Gold Safe</p>

                        <div class="list-point">
                          <ul class="safe-rules">
                            <li>BVN required</li>
                            <li>No Limits</li>
                          </ul>
                        </div>

                        <button
                          class="btn"
                          data-toggle="modal"
                          data-target="#safe_terms"
                          @click="selectSafeType('yellow')"
                        >
                          <span>{{ clickOption }}</span>
                        </button>
                      </div>
                    </div>
                    <div class="col-md-6" v-if="!safeOption">
                      <div class="blue_safe">
                        <img src="../assets/images/safe2.svg" alt="safe" />
                        <p>Blue Safe</p>
                        <div class="list-point">
                          <ul class="safe-rules">
                            <li>No BVN required</li>
                            <li>500,000 funds limit</li>
                          </ul>
                        </div>
                        <button
                          class="btn"
                          data-toggle="modal"
                          data-target="#safe_terms"
                          @click="selectSafeType('blue')"
                        >
                          Select
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="registedForSafe === true">
              <!-- v-if="whichSafe == 'yellowSafe'" -->
              <div class="row row_holder">
                <div class="col-md-6 col-sm-12">
                  <div
                    class="wallet_div"
                    eye1
                    :class="[
                      whichSafe == 'blueSafe' ? 'blueCard' : 'wallet_div'
                    ]"
                  >
                    <!-- Virtual Card section -->
                    <div class="">
                      <div class="fund_btn">
                        <!-- <button class="btn" @click="paymentModalFund()" disabled> -->
                        <!-- <button
                          v-if="whichSafe == 'blueSafe'"
                          class="btn"
                          @click="triggerPaymentForBlueSafe"
                        >
                          Fund Project
                        </button> -->
                        <button
                          :disabled="projects.length == 0"
                          class="btn"
                          data-toggle="modal"
                          data-target="#fundproject"
                        >
                          Fund Project
                          <!-- <img src="../assets/images/safe.svg" alt="fund" /> -->
                        </button>
                        <button class="btn" v-if="whichSafe != 'blueSafe'">
                          <router-link to="/dashboard/autodebithistory">
                            Auto Debit
                          </router-link>
                        </button>
                        <div class="dropleft" v-else>
                          <div
                            class="roundedMenun"
                            data-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span class="dot"></span>
                            <span class="dot"></span>
                            <span class="dot"></span>
                          </div>
                          <div class="dropdown-menu dot-menu">
                            <button
                              class="dropdown-item"
                              type="button"
                              data-toggle="modal"
                              data-target="#fundbluesafe"
                            >
                              Fund SAFE
                            </button>
                            <button
                              class="dropdown-item"
                              type="button"
                              @click="navigateOption(2)"
                            >
                              Auto Debit
                            </button>
                            <button
                              @click="navigateOption(3)"
                              class="dropdown-item"
                              type="button"
                            >
                              SAFE History
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="safe_amount">
                        <!-- Number(property.price).toLocaleString() -->
                        <div class="amount-in-safe">
                          <p v-if="amountVisiblilty">
                            &#8358;{{
                              Number(safeDetails.balance).toLocaleString()
                            }}
                          </p>
                          <p v-if="!amountVisiblilty">
                            ************
                          </p>
                          <div class="visibility_eye">
                            <small v-if="amountVisiblilty">Safe Balance</small>
                          </div>

                          <div class="text-left">
                            <span
                              v-if="amountVisiblilty"
                              class="uncleared_amount_yellow"
                              eye1
                              :class="[
                                whichSafe == 'blueSafe'
                                  ? 'uncleared_amount_blue'
                                  : 'uncleared_amount_yellow'
                              ]"
                              >&#8358;{{
                                Number(
                                  safeDetails.unclearedbalance
                                ).toLocaleString()
                              }}</span
                            >

                            <small
                              v-if="amountVisiblilty"
                              class="uncleared_text"
                              :class="[
                                whichSafe == 'blueSafe'
                                  ? 'uncleared_text_blue'
                                  : 'uncleared_text_yellow'
                              ]"
                            >
                              Uncleard Balance</small
                            >
                          </div>
                        </div>
                        <div>
                          <img
                            class=""
                            :class="[whichSafe == 'blueSafe' ? 'eye2' : 'eye1']"
                            v-if="!amountVisiblilty"
                            @click="amountVisiblilty = true"
                            src="../assets/images/hide-eye.svg"
                            alt="hide"
                            style="cursor: pointer;"
                          />
                          <img
                            :class="[whichSafe == 'blueSafe' ? 'eye2' : 'eye1']"
                            v-if="amountVisiblilty"
                            @click="amountVisiblilty = false"
                            src="../assets/images/view-eye.svg"
                            alt="show"
                            style="cursor: pointer;"
                          />
                        </div>
                        <!-- <img
                          class="safe-logo"
                          src="../assets/images/safe2.svg"
                          alt="safe"
                        /> -->
                      </div>
                      <!-- <h5 class="balance_text">Wallet balance</h5> -->

                      <div class="performance_index">
                        <div class="index_number">
                          <img
                            src="../assets/images/performing-index.svg"
                            alt="hide"
                          />
                          <small>0/365</small>
                        </div>
                        <div
                          class="safe-account-number-holder"
                          v-if="whichSafe === 'yellowSafe'"
                        >
                          <div class="safe-account-number">
                            <p>{{ safeDetails.safedata.squadaccountno }}</p>
                            <h6>
                              {{ safeDetails.safedata.first_name }}
                              {{ safeDetails.safedata.last_name }}
                            </h6>
                            <!-- <h6>Safe Account Number</h6> -->
                          </div>
                          <img
                            src="../assets/images/banks-logo.svg"
                            alt="bank-logo"
                          />
                        </div>

                        <div
                          class="safe-account-number-holder"
                          v-if="whichSafe === 'blueSafe'"
                        >
                          <button
                            class="upgradeSafeBtn"
                            @click="upgradeSafe('yellow')"
                          >
                            <img
                              class="pr-1"
                              src="../assets/images/accountupgrade.svg"
                              alt="upgrade"
                            />
                            Upgrade Account
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="bonus">
                    <div>
                      <button class="btn">
                        Bonus Account
                        <img src="../assets/images/safe.svg" alt="safe" />
                      </button>
                      <!-- <img src="../assets/images/info-dot.svg" alt="info" /> -->
                    </div>
                    <div class="bonus_amount">
                      <h4 v-if="amountVisibliltyBonus">
                        &#8358;{{
                          Number(bonusData.clearedbalance).toLocaleString()
                        }}
                      </h4>
                      <h4 v-if="!amountVisibliltyBonus">
                        ************
                      </h4>
                      <div>
                        <img
                          class="eye2"
                          v-if="!amountVisibliltyBonus"
                          @click="amountVisibliltyBonus = true"
                          src="../assets/images/hide-eye.svg"
                          alt="hide"
                          style="cursor: pointer;"
                        />
                        <img
                          class="eye2"
                          v-if="amountVisibliltyBonus"
                          @click="amountVisibliltyBonus = false"
                          src="../assets/images/view-eye.svg"
                          alt="show"
                          style="cursor: pointer;"
                        />
                      </div>
                    </div>
                    <div class="widthdraw">
                      <button
                        class="btn"
                        data-toggle="modal"
                        data-target="#bank_option"
                        disabled
                      >
                        Transfer
                      </button>
                      <div class="uncleared_bonus" v-if="amountVisibliltyBonus">
                        <span
                          >&#8358;{{
                            Number(bonusData.unclearedbalance).toLocaleString()
                          }}</span
                        >
                        <small>Uncleared Balance</small>
                      </div>
                      <div
                        class="uncleared_bonus"
                        v-if="!amountVisibliltyBonus"
                      >
                        <span>**********</span>
                        <!-- <small>Uncleared Balance</small> -->
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-sm-12 d-none">
                  <div class="fund">
                    <div class="">
                      <div class="fund_wallet">
                        <p>Fund Wallet</p>
                        <img src="../assets/images/wallet-b.svg" alt="wallet" />
                      </div>
                      <div class="fund_type">
                        <button class="btn btn-block bank">
                          <img
                            src="../assets/images/double-arrow.svg"
                            alt="transfer"
                          />
                          Bank Transfer
                        </button>
                      </div>

                      <div class="fund_type">
                        <button class="btn btn-block paystack">
                          <img
                            src="../assets/images/block.svg"
                            alt="transfer"
                          />Paystack
                        </button>
                      </div>
                      <div class="fund_type">
                        <button class="btn btn-block flutter">
                          <img
                            src="../assets/images/flutter.svg"
                            alt="transfer"
                          />Flutterwave
                        </button>
                      </div>
                      <div class="fund_type">
                        <button class="btn btn-block enaira">
                          <img
                            src="../assets/images/e-naira.svg"
                            alt="transfer"
                          />e-Naira
                        </button>
                      </div>
                      <!-- <hr> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Recent transaction list begins -->
            <div class="invit-text-holder mt-5" v-if="transactions.length > 0">
              <h3 class="invite-list">Recent Transactions</h3>
            </div>

            <div class="table-wrap clearfix" v-if="transactions.length > 0">
              <div class="table-responsive">
                <table class="table seperate_border">
                  <tbody v-for="(data, index) in transactions" :key="index">
                    <tr>
                      <th scope="row">
                        <div
                          v-if="data.type == 'credit'"
                          class="green-dot"
                        ></div>
                        <div v-if="data.type == 'debit'" class="red-dot"></div>
                      </th>
                      <td style="font-weight: bold;">
                        &#8358;{{ Number(data.amount).toLocaleString() }}
                      </td>
                      <td>
                        {{ data.description }}
                      </td>
                      <td>{{ data.created_at | moment }}</td>
                      <th>{{ data.channel }}</th>
                      <th>{{ data.transaction_reference }}</th>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-if="transactions.length > 0">
                <div v-if="transactions.length >= 10">
                  <router-link to="/dashboard/safetransaction">
                    <button class="btn view-more">
                      View More...
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="mt-5"></div>
            <!-- Recent transaction list ends -->

            <!-- referal list begins -->
            <div class="invit-text-holder" v-if="invites.length > 0">
              <h3 class="invite-list">Invite List</h3>
              <div class="color-dots-holder">
                <div class="green-dot"></div>
                <span>{{ green }}</span>
                <div class="yellow-dot"></div>
                <span>{{ yellow }}</span>
                <div class="red-dot"></div>
                <span>{{ red }}</span>
              </div>
            </div>

            <div class="table-wrap clearfix" v-if="invites.length > 0">
              <div class="table-responsive">
                <table class="table seperate_border">
                  <tbody v-for="(member, index) in invites" :key="index">
                    <tr>
                      <th scope="row">
                        <div
                          class="green-dot"
                          v-if="member.color == 'green'"
                        ></div>
                        <div
                          class="yellow-dot"
                          v-if="member.color == 'yellow'"
                        ></div>
                        <div class="red-dot" v-if="member.color == 'red'"></div>
                      </th>
                      <td style="font-weight: bold; color: black">
                        {{ member.firstname }} {{ member.lastname }}
                      </td>
                      <td>{{ member.phone }}</td>
                      <td>{{ member.email }}</td>
                      <td>{{ member.created_at | moment }}</td>
                      <th>{{ member.amount }}</th>
                      <th @click="copyLink(member.link)">
                        <button class="btn copyBtn">Copy invite link</button>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="mt-5"></div>
            <!-- Referal list ends -->

            <!-- Modal for successful bank withdrawal begins -->
            <div
              class="modal fade"
              id="withdraw_success"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="close_bank">
                    <h6>Withdraw to bank account</h6>
                    <small data-dismiss="modal" aria-label="Close">x</small>
                  </div>
                  <div class="modal-body">
                    <div class="bank_widthraw_successful">
                      <div class="withdraw_image_wallet">
                        <img
                          src="../assets/images/double-arrow-blue.svg"
                          alt="arrow"
                        />
                        <img
                          src="../assets/images/wallet-blue.svg"
                          alt="wallet"
                        />
                      </div>
                      <div class="withdrawal_text_modal">
                        <p>
                          A withdrawal of #2,320,000.22 has been initiated.
                          Please wait while we complete your withdrawal process.
                        </p>
                      </div>
                      <div class="withdraw_footer">
                        <img src="../assets/images/blue-tic.svg" alt="good" />
                        <p>.... We Got You Covered !</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Modal for successful bank withdrawal ends -->

            <!-- Modal for account details to be withdrawn from -->
            <div
              class="modal fade"
              id="bank_option"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="close_bank">
                    <h6>Withdraw to bank account</h6>
                    <small data-dismiss="modal" aria-label="Close">x</small>
                  </div>
                  <div class="input_side">
                    <form>
                      <div class="form-group">
                        <label class="select-label input_label" for="bank_name"
                          >Bank name</label
                        >
                        <select
                          class="form-control bank_input"
                          name="bank_name"
                          v-model="bankname"
                        >
                          <option value="" selected disabled>--select--</option>
                          <option
                            :value="bank"
                            v-for="(bank, index) in banks"
                            :key="index"
                            >{{ bank }}</option
                          >
                        </select>
                      </div>
                      <div class="form-group">
                        <label class="input_label" for="account_name"
                          >Account name</label
                        >
                        <input
                          type="text"
                          class="form-control bank_input"
                          id="account_name"
                        />
                      </div>
                      <div class="form-group">
                        <label class="input_label" for="account"
                          >Account number</label
                        >
                        <input
                          type="number"
                          class="form-control bank_input"
                          id="account"
                        />
                      </div>

                      <div class="form-group">
                        <label class="input_label" for="amount"
                          >Amount(&#8358;)</label
                        >
                        <input
                          type="number"
                          class="form-control bank_input"
                          id="amount"
                        />
                      </div>

                      <div class="form-group send_btn">
                        <button
                          class="btn btn-block"
                          @click.prevent="bankTransferWithdrawal()"
                        >
                          Send
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <!-- Modal for account deatils to be wthdrawn from end -->

            <!-- Modal to validate bank transfer with OTP begins -->
            <div
              class="modal fade"
              id="agree"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="close_bank">
                    <h6>Withdraw to bank account</h6>
                    <small data-dismiss="modal" aria-label="Close">x</small>
                  </div>
                  <div class="modal-body">
                    <p class="modal_veri_text">
                      A verification code has been sent to your registered
                      email.
                    </p>
                    <h6 class="enter_code_text">Kindly enter the code here</h6>
                    <!-- <h5 class="otp__text">{{OTP}}</h5> -->
                    <div class="otp-holder">
                      <input
                        @keyup="keytab($event)"
                        v-model="one"
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        :maxlength="maxLength"
                      />
                      <input
                        @keyup="keytab($event)"
                        v-model="two"
                        type="number"
                        class="form-control"
                        id="exampleInputEmail12"
                        maxlength="1"
                      />
                      <input
                        @keyup="keytab($event)"
                        v-model="three"
                        type="number"
                        class="form-control"
                        id="exampleInputEmail13"
                        maxlength="1"
                      />
                      <input
                        @keyup="keytab($event)"
                        v-model="four"
                        type="number"
                        class="form-control"
                        id="exampleInputEmail14"
                        maxlength="1"
                      />
                    </div>

                    <div class="otp__btn">
                      <button
                        class="btn"
                        @click="
                          concatOtp();
                          bankTransferOTP();
                        "
                      >
                        Confirm Withdrawal
                        <i
                          class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                          v-if="loader"
                        ></i>
                      </button>
                      <p>Didn’t get the code? <span>Resend Code</span></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Modal to validate bank transfer WITH OTP ends -->
          </div>
        </div>

        <!-- Modal for terms and conditions -->
        <div
          class="modal fade"
          id="safe_terms"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="close_bank modal_safe_activation_text">
                <h6 class="">Safe Activation</h6>
                <small
                  @click="closeTC()"
                  data-dismiss="modal"
                  aria-label="Close"
                  >x</small
                >
              </div>
              <div class="modal-body safe_terms_content">
                <div class="terms_condition">
                  <p>
                    Please read carefully before using this service. SAFE is a
                    product from Shelta that seeks to solve the problems
                    associated with payment of rent and funding for other
                    property related projects (e.g., property acquisition,
                    mortgage, etc) by leveraging on regulated institutional
                    services/infrastructure. The Shelta Safe service, website
                    and/or software application (the “Service”) enables you to
                    receive our online money saving services in accordance with
                    our products under these Safe Terms and Conditions (the
                    “Terms”). These Terms is a bond agreement between Shelta
                    Panacea Limited, a PropTech company registered under the
                    Laws of the Federal Republic of Nigeria having its
                    registered office at No. 4 Amurie Omanze Street, Garki 2,
                    Abuja (“we”,”us”, “Safe Products”); and you as our customer,
                    holding a valid Bank Verification Number (BVN), and being
                    entitled to receive financial services under these Terms.
                  </p>
                  <h5>1. THIS IS A CONTRACT</h5>
                  <!-- <ul class="benefits">
                    <li>Tenant Management</li>
                    <li>Tenant Management</li>
                    <li>Coca Cola</li>
                  </ul> -->
                  <p>
                    These Terms constitute a contract between you and Shelta.
                    Please do not use the Service if you do not accept these
                    Terms. By using any part of the Service you accept these
                    Terms. If you are under eighteen (18) years of age, you may
                    not use the Service.
                  </p>

                  <h5>2. ACCOUNT REGISTRATION</h5>
                  <p>
                    You must register an account with valid information. To use
                    the Service, you must:
                  </p>
                  <ul class="benefits">
                    <li>Signup on myshelta.com</li>
                    <li>Provide a valid mobile phone number</li>
                    <li>Login to your account</li>
                    <li>Activate your SAFE</li>
                    <li>Agree/Obtain your GTB virtual account</li>
                    <li>Accept the present Terms</li>
                    <li>
                      Submit such other and additional information as Shelta may
                      request during registration
                    </li>
                    <li>Start funding SAFEly</li>
                  </ul>
                  <p>
                    You agree to provide true, accurate and complete information
                    about yourself as prompted by Shelta during the account
                    registration process. You will be given the option of
                    receiving a unique PIN for purposes of reusing your account.
                    you are responsible for keeping your PIN secure. Your
                    personal information is highly protected in accordance with
                    our Privacy Policy published and updated on our platform.
                    The Shelta Safe Products shall only be made available to you
                    once our Know Your Customer process has been complied with
                    by you and your identification has been verified.
                  </p>
                  <h5>3. SERVICES DESCRIPTION</h5>
                  <p>
                    Various sub-products: Under the Shelta Safe, we may provide
                    various kinds of sub-products for your choice to deposit
                    savings amount and earn bonuses, including but not limited
                    to Rent, Land Purchase, Mortgage, Property purchase,
                    Targets, Shelta’s Credit Scoring system, collectively we
                    refer as “Shelta Safe Products”. Such sub-products contains
                    fixed bonuses determined by the performance index of the
                    SAFER or any other method which may include; A 40% Safe Term
                    Gap-Financing for safers with up to 60% Accumulated Project
                    Fund compliance rating for Rent, A 25% Safe Term
                    Gap-Financing for safers with up to 75% Accumulated Project
                    Fund compliance rating for Land Purchase, A 60% Safe Term
                    Gap-Financing for safers with up to 40% Accumulated Project
                    Fund compliance rating for Mortgage, A 20% Safe Term
                    Gap-Financing for safers with up to 80% Accumulated Project
                    Fund compliance rating for Property Purchase, You can find
                    our various sub-products on the pages of our platform and
                    choose one or more that suits you. For detailed information
                    of each sub-products, please refer to Q & A, sub-products
                    policies or any such information on our platform. All those
                    written information shall be regarded as an internal part of
                    this terms and conditions.
                  </p>
                  <h5>Deposit with bonus</h5>
                  <p>
                    There are two major types of deposit modes provided to you,
                    Term deposit or Demand deposit. No matter which of the modes
                    you choose, you can deposit your money electronically into
                    the Virtual account opened in our Platform to enjoy our Safe
                    services, and receive bonuses according to bonus rates we
                    offered on our platform. Under the Term Deposit mode; you
                    can make such savings within specified applicable days which
                    may be Daily, Weekly Monthly or Quarterly (‘Savings Term’).
                    Under the Demand Deposit mode (Random Deposit), you are at
                    liberty to make such savings at any time or at will.
                  </p>
                  <h5>Withdrawal</h5>
                  <p>
                    You are entitled to withdrawal of your deposited money at
                    your own set withdrawal date without charges (according to
                    your Deposit mode, ‘Savings Term’) or at any time in
                    accordance with our withdrawal policies. However,
                    Withdrawals made before time will be charged, between 10 -
                    20% of the Accumulated Project Fund. (Incomplete Project).
                    Withdrawal is allowed for safers with Accumulated Project
                    Fund of up to 30%. A SAFER will be charged 20% when
                    Withdrawing with Accumulated Project Fund of up to 40%.,
                    while A SAFER with Accumulated Project Fund of above 40%
                    will be charged 10% while withdrawing. The duration of SAFE
                    plan is flexible but not less than 3months. These measures
                    are put in place to encourage completion of projects and to
                    enable SAFE activate Gap-Financing service when requested at
                    no extra fees.
                  </p>
                  <h5>Liquidate</h5>
                  <p>
                    Means you can withdraw your saved amount with the
                    calculation of applicable bonuses. You are entitled to
                    liquidate your savings amount according to liquidation
                    policies of each safe products.
                  </p>
                  <h5>Consumption</h5>
                  <p>
                    You can use your Safe balance for any applicable
                    consumption, whether it’s for Rent, Property purchase,
                    Mortgage or Land purchase. etc.
                  </p>
                  <h5>Transfer</h5>
                  <p>
                    You can transfer your Virtual account balance to your Shelta
                    safe to fund other sub-products.
                  </p>
                  <h5>4. AUTOMATIC TRANSFER AND PROJECT FUNDING</h5>
                  <p>
                    You can choose automatic transfer under these Terms by
                    ticking the box of “Accept Automatic Saving” and clicking
                    the button of “Turn on Auto Save”. By you authorizing Shelta
                    Safe Products to deduct a certain sum from your Virtual
                    account to be used for funding either of the Shelta Safe
                    Projects. By activating the Automatic Transfer, you agree
                    for Shelta Safe to send deduction instructions to your
                    virtual Account and such instructions shall be deemed as
                    your own. You shall be responsible for the result of such
                    instructions. You warrant and represent that the balance is
                    legitimately owned by you and that agreeing and acceptance
                    of this Agreement is of your true intention. You understand
                    and agree that, in any circumstance that Shelta Safe did not
                    send deduction instruction or failed to successfully deduct
                    your balance for saving, Shelta will not be liable or
                    responsible for any loss of such failure. You understand and
                    agree that, due to deduction of your balance and the
                    corresponding savings, your balance of Shelta Safe may
                    become insufficient to support your other demands which may
                    cause loss (including failing to fund other projects and the
                    loss of earnings), and Shelta will not be liable or
                    responsible for any loss of such. You can also terminate
                    this Agreement at any time by clicking the button of “Turn
                    off Auto Save”. Termination of this Agreement will not
                    affect the validity and effectiveness of deductions and
                    savings made before such termination. You might also be
                    entitled to fund any project on your Shelta Safe by
                    deducting the balance of your debit card or through bank
                    transfer once applicable.
                  </p>
                  <h5>5. BONUS</h5>
                  <p>
                    Any bonus shall only be payable on funds that have remained
                    in your Shelta Safe for a period of not less than such
                    indicated on your investment term, and which bonus shall be
                    at a rate determined by Shelta from time to time and payable
                    pursuant to the amount shown on the application. You
                    acknowledge and agree that Shelta shall have the right to
                    amend the bonus rate applicable to your Safe project and you
                    hereby indemnify and waive any/all claims of any nature
                    against Shelta for bonuses on your Shelta Safe project. Your
                    bonus rate might vary according to the amount you save, the
                    period you kept, and the sub-products you choose. We highly
                    recommend you to check your projects and your bonus. You
                    fully understand that you shall pay for any taxes due to
                    your bonus earnings, including but not limited to
                    Withholding Tax. We confirm that the bonus we offer to you
                    shall be in compliance with applicable laws and regulations.
                    In case there is any change in the laws and regulations
                    resulting in the decrease of your bonus received, we are not
                    obligated to compensate for it.
                  </p>
                  <h5>6. ANTI-FRAUD</h5>
                  <p>
                    You agree and acknowledge that where the funds in your
                    Shelta Safe have been flagged as or are suspected of being
                    fraudulent in nature, Shelta may immediately suspend your
                    account and retain the funds pending an investigation. If
                    you elect to lock an amount in your Shelta Safe, such lock
                    will remain in place for an agreed period time. If you elect
                    to lift the lock on the account prior to the agreed date,
                    you agree that Shelta Safe may impose a penalty on you for
                    lifting such lock and which amount Shelta may immediately
                    deduct from your Shelta Safe balance. Bonuses accrued on
                    your Shelta Safe will be calculated and credited annually.
                    If you wish to withdraw funds from your Shelta Safe, such
                    funds shall only be transferred to your Virtual account. You
                    irrevocably agree that where you select the option to
                    transfer funds from your Shelta Safe balance to your Virtual
                    account such transfer shall take place daily at a time
                    designated by us. The automatic transfer can be switched off
                    at any time by you. At our discretion, you may be granted
                    the option to effect payment for other Shelta Safe Projects
                    (as designated by Shelta from time to time) with funds held
                    in your Shelta Safe.
                  </p>
                  <h5>7. LIABILITY FOR NEGLIGENCE</h5>
                  <p>
                    SHELTA OR ITS PARTNERS SHALL IN NO EVENT BE LIABLE FOR ANY
                    DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL,
                    CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER YOU MAY
                    INCUR AS A RESULT OF WRONGFUL/NEGLIGENT USAGE OF ANY OF THE
                    SHELTA SAFE SERVICES.
                  </p>
                  <h5>8. GUARANTORS</h5>
                  <p>
                    You agree to provide such numbers of verifiable guarantors
                    as may be required of you by Shelta to serve as security in
                    the event of any default(s) that may arise out of your
                    inability to repay the Safe Term Gap Financing as may be
                    granted to you, provided that you maintain a healthy Savings
                    Compliance Rating or Performance Index on the Shelta Safe.
                    Such a guarantor may be either; A beneficiary of the Shelta
                    Safe system (Member of the Shelta Community) with good
                    savings rating and credit score, or A Verifiable and
                    Credible person (although not on the Shelta Community) who
                    accepts to guarantee you.
                  </p>
                  <h5>9. DISPUTES AND ARBITRATION</h5>
                  <p>
                    You agree to arbitrate any disputes. This Agreement is
                    subject to, and shall be governed by, and construed in
                    accordance with the Laws of The Federal Republic of Nigeria,
                    without reference to the principles of conflict of laws
                    thereof. Any matters arising concerning the interpretation,
                    validity or implementation of this Agreement not solved by
                    mutual agreement between the Parties shall be submitted to
                    arbitration in the English language before a sole arbitrator
                    to take place in Abuja, Nigeria. The arbitration shall be
                    conducted pursuant to the Rules of Arbitration prevalent in
                    Nigeria. The arbitral decision shall be final and binding on
                    the Parties and may be made an order of court. The Parties
                    unconditionally consent and submit to the jurisdiction of
                    the High Court of the Federal Capital Territory, Nigeria for
                    such purpose. Nothing in this Agreement will be deemed as
                    preventing Shelta from seeking injunctive relief (or any
                    other provisional remedy) from any court having jurisdiction
                    over the Parties and the subject matter of the dispute as is
                    necessary to protect Shelta’s name, proprietary information,
                    trade secrets, know-how, or any other intellectual property
                    rights.
                  </p>
                  <h5>10. ENFORCEMENT</h5>
                  <p>
                    If after arbitration, a court of competent jurisdiction or
                    other competent authority finds any part of this Agreement
                    invalid, unlawful or unenforceable, then such part shall be
                    severed from the remainder of this Agreement which will
                    continue to be valid and enforceable to the fullest extent
                    permitted by law.
                  </p>
                  <h5>11. DURATION</h5>
                  <p>
                    This Agreement shall come into force on the date of final
                    execution and will remain in force for THREE CALENDAR YEARS
                    until its termination, whether by effluxion of time, or as a
                    result of breach of any of its covenants, if same was not
                    rectified within seven days of receipt of notice of breach
                    in writing or on the determination and redemption of all the
                    Investments made on behalf of the Investor. Either party can
                    voluntarily terminate this Agreement upon giving TEN DAYS’
                    notice of such intention in writing to the other party.
                    However, the termination of this contract under any of the
                    above circumstances shall not affect the existing
                    obligations and liabilities of the parties herein.
                  </p>
                  <h5>12. NO AGENCY</h5>
                  <p>
                    Neither Party is authorized to act as an agent for, or legal
                    representative of the other party and neither Party shall
                    have the authority to assume or create any obligation on
                    behalf of, in the name of, or binding upon the other Party,
                    save and except with the express consent in writing of the
                    other Party.
                  </p>
                  <h5>13. MODIFICATION</h5>
                  <p>
                    Shelta may modify these Terms. These Terms and related
                    policies (including but not limited to the Privacy Policy)
                    may be modified by Shelta without notice at any time in the
                    future. Changes will be posted. By using the Service, you
                    agree to be bound by the latest version of these Terms. It
                    is your responsibility to remain informed of any changes.
                  </p>
                  <h5>14. OTHERS</h5>
                  <p>
                    <span class="side_bold">Alteration:</span>
                    No alteration, variation or agreed cancellation of this
                    agreement, and this product, shall be of any effect unless
                    directed so by us.
                  </p>
                  <p>
                    <span class="side_bold">Binding:</span>
                    Any decision, exercise of discretion, judgement or opinion
                    or approval of any matter mentioned in this Agreement or
                    arising from it shall be binding on the parties only if in
                    writing unless otherwise expressly provided in this
                    Agreement.
                  </p>
                  <p>
                    <span class="side_bold">Notice:</span>
                    Any notice pursuant to this Agreement shall be given by fax,
                    electronic mail or letter and the onus of confirmation of
                    receipt of such notices shall be on the sender.
                  </p>
                  <p>
                    <span class="side_bold">Whole Agreement:</span>
                    This Agreement constitutes the whole agreement between the
                    parties in regard to the subject matter hereof and no
                    warranties or representations of any nature whatsoever other
                    than set out in this agreement have been given by any of the
                    parties.
                  </p>
                  <p>
                    <span class="side_bold">Waiver/Relaxation:</span>
                    No relaxation or indulgence which Shelta may show to you
                    shall in any way prejudice or be deemed to be a waiver of
                    its rights hereunder. No term shall survive at the expiry or
                    termination of this Agreement unless expressly provided. No
                    delay or failure by either party to exercise any of its
                    powers, rights or remedies under this Agreement will operate
                    as a waiver of them, nor will any single or partial exercise
                    of any such powers, rights or remedies preclude any other or
                    future exercise of them.
                  </p>
                  <p>
                    <span class="side_bold">Survival:</span>
                    Each and every provision of this Agreement (excluding only
                    those provisions which are essential at law for a valid and
                    binding Agreement to be constituted) shall be deemed to be
                    separate and severable from the remaining provisions of this
                    Agreement. If any of the provisions of this Agreement
                    (excluding only those provisions which are essential at law
                    for a valid and binding Agreement to be constituted) is
                    found by any court of competent jurisdiction to be invalid
                    and/or unenforceable then, notwithstanding such invalidity
                    and/or unenforceability, the remaining provisions of this
                    Agreement shall be and remain of full force and effect.
                  </p>
                  <p>
                    <span class="side_bold">Confidential Information:</span>
                    Each and every provision of this Agreement (excluding only
                    those provisions which are essential at law for a valid and
                    binding Agreement to be constituted) shall be deemed to be
                    separate and severable from the remaining provisions of this
                    Agreement. If any of the provisions of this Agreement
                    (excluding only those provisions which are essential at law
                    for a valid and binding Agreement to be constituted) is
                    found by any court of competent jurisdiction to be invalid
                    and/or unenforceable then, notwithstanding such invalidity
                    and/or unenforceability, the remaining provisions of this
                    Agreement shall be and remain of full force and effect.
                  </p>
                  <p>
                    <span class="side_bold">Force Majeure:</span>
                    Each and every provision of this Agreement (excluding only
                    those provisions which are essential at law for a valid and
                    binding Agreement to be constituted) shall be deemed to be
                    separate and severable from the remaining provisions of this
                    Agreement. If any of the provisions of this Agreement
                    (excluding only those provisions which are essential at law
                    for a valid and binding Agreement to be constituted) is
                    found by any court of competent jurisdiction to be invalid
                    and/or unenforceable then, notwithstanding such invalidity
                    and/or unenforceability, the remaining provisions of this
                    Agreement shall be and remain of full force and effect.
                  </p>
                </div>
                <div class="checkbox_footer">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="terms_agree"
                      @change="navigateToActivateSafe()"
                    />
                    <label class="form-check-label" for="terms_agree">
                      I agree to the terms of use of this service
                    </label>
                    <!-- <button
                      class="btn btn-primary"
                      @click="navigateToActivateSafe()"
                    >
                      I Agree
                    </button> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal for terms and condition ends -->

        <!-- Modal to fund project from safe begins -->
        <div
          class="modal fade"
          id="fundproject"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content fundmodalContent">
              <div
                class="closeFundProject"
                style="border-radius: 20px 20px 0 0;"
              >
                <h6>Transfer funds to project</h6>
                <small data-dismiss="modal" aria-label="Close">x</small>
              </div>
              <div class="modal-body">
                <p class="transferSafeBalance">
                  Safe Balance:
                  <span>
                    &#8358;{{
                      Number(safeDetails.balance).toLocaleString()
                    }}</span
                  >
                </p>
                <form>
                  <div class="form-group">
                    <select
                      class="form-control"
                      id="projects"
                      v-model="projectId"
                    >
                      <option slected disabled value="">Select project</option>
                      <option
                        v-for="(item, index) in projects"
                        :key="index"
                        :value="item.id"
                        >{{ item.title }}</option
                      >
                    </select>
                  </div>
                  <div class="form-group">
                    <input
                      onfocus="(this.type='text')"
                      type="number"
                      class="form-control"
                      id="amountfund"
                      placeholder="Enter amount"
                      v-model="transferAmount"
                    />
                  </div>
                  <div class="form-group transfer-btn">
                    <button class="btn btn-block" @click.prevent="fundProject">
                      Transfer
                      <i
                        class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                        v-if="fundLoader"
                      ></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal to fund project from safe ends -->

        <!-- Modal to fund blue safe amount begins -->
        <div
          class="modal fade"
          id="fundbluesafe"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content fundmodalContent">
              <div
                class="closeFundProject"
                style="border-radius: 20px 20px 0 0;"
              >
                <h6>FUND BLUE SAFE</h6>
                <small
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="
                    closedPaystack;
                    fundLoaderBlue = false;
                  "
                  >x</small
                >
              </div>
              <div class="modal-body">
                <p class="transferSafeBalance">
                  Safe Balance:
                  <span>
                    &#8358;{{
                      Number(safeDetails.balance).toLocaleString()
                    }}</span
                  >
                </p>
                <form>
                  <div class="form-group">
                    <!-- <label for="exampleInputEmail1">Email address</label> -->
                    <input
                      onfocus="(this.type='text')"
                      type="number"
                      class="form-control"
                      id="amountfund"
                      placeholder="Enter amount"
                      v-model="blueSafeFundAmount"
                    />
                  </div>

                  <div class="form-group transfer-btn">
                    <button
                      class="btn btn-block"
                      @click.prevent="paymentModalFund2"
                    >
                      FUND
                      <i
                        class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                        v-if="fundLoaderBlue"
                      ></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal to fund blue safe amount ends -->

        <!-- <component class="more-content" :is="PaymentModal"></component> -->
        <PaymentModal
          @paystackReference="dataFromPaymentModal($event)"
          :dataForPayment="modalData"
          v-model="paymentM"
        ></PaymentModal>
        <!-- <component class="" :header="headerCaption" :is="PaymentModal"></component> -->
      </div>
      <!-- <Footer /> -->
    </div>
  </div>
</template>

<script>
import SmallNav from "./SmallNav";
import PageLoader from "./PageLoader.vue";
import PaymentModal from "./PaymentModal.vue";
// import { defineComponent } from '@vue/composition-api'
// import Footer from "../views/FooterMain2.vue";
import Pagination from "../components/Pagination.vue";
import { Service } from "../store/service";
const Api = new Service();
import $ from "jquery";
import moment from "moment";
import Vue from "vue";

export default {
  name: "Safe",
  // props: ["page"],
  components: {
    SmallNav,
    PageLoader,
    PaymentModal
    // Pagination
    // Footer,
  },
  data() {
    return {
      PaymentModal: "",
      paymentM: false,
      fundLoaderBlue: false,
      fundLoader: false,
      maxLength: 1,
      modalData: {},
      pageData: {},
      description: "Desc",
      id: "",
      user: "",
      firstname: "",
      lastname: "",
      currentPath: "Overview",
      amountVisiblilty: false,
      amountVisibliltyBonus: false,
      registedForSafe: false,
      pageLoader: false,
      one: "",
      two: "",
      three: "",
      four: "",
      loader: "",
      wallet: "",
      panel: 0,
      error: false,
      uploading: false,
      fileselected: false,
      filePath: "",
      progress: 0,
      file: "",
      headerText1: true,
      headerText2: false,
      headerText3: false,
      userid: "",
      percentageFull: false,
      transactions: [],
      stateData: "",
      bonusData: "",
      logger: "",
      banks: [],
      safeAmount: "hide",
      displayAmount: false,
      displayAmount2: false,
      // bank: "",
      bankname: "",
      whichSafe: "",
      safeDetails: {},
      safeType: "",
      invites: [],
      green: "",
      yellow: "",
      red: "",
      projects: [],
      projectId: "",
      transferAmount: "",
      formatedTransferAmount: "",
      formatLang: "en-EN",
      formatStyle: "currency",
      formatCurrency: "NGN",
      pageInfo: {},
      safeOption: false,
      clickOption: "",
      blueSafeFundAmount: "",
      reference: ""
    };
  },
  // beforeCreate() {
  //   this.serviceData();
  // },
  watch: {
    transferAmount: function(newValue) {
      const result = newValue
        .replace(/[^.\d]/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Vue.nextTick(() => (this.transferAmount = result));
    },
    blueSafeFundAmount: function(newValue) {
      const result = newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Vue.nextTick(() => (this.blueSafeFundAmount = result));
    }
  },
  computed: {
    // formatter() {
    //   return new Intl.NumberFormat(this.formatLang, {
    //     style: this.formatStyle,
    //     currency: this.formatCurrency
    //   });
    // },
    // formattedCost() {
    //   return this.formatter.format(this.transferAmount);
    // }
  },
  created() {
    this.$root.$refs.A = this;
    this.$root.$refs.C = this;
    this.$root.$refs.B = this;
    // console.log("Funt type", this.modalData.paymentType)
    // if (this.modalData.paymentType == "fundbluesafe") {
    //   return (this.modalData.headerCaption = "Fund Your Blue SAFE");
    // } else {
    //   this.modalData.headerCaption = "Payment";
    // }
  },
  mounted() {
    // setTimeout(() => {
    //   this.oldValue = this.$refs.pikin.response;
    //   console.log("pikin data", this.$refs.pikin.response);
    // }, 4000);

    this.logger = localStorage.getItem("shelta-loger");
    this.firstname = JSON.parse(localStorage.getItem("shelta-user")).firstname;
    this.lastname = JSON.parse(localStorage.getItem("shelta-user")).lastname;
    this.user = JSON.parse(localStorage.getItem("shelta-user"));

    if (this.user.accounttype != "individual") {
      this.safeOption = true;
      this.clickOption = "Click to Begin";
    } else {
      this.clickOption = "Select";
    }

    // this.serviceData();
    this.getProjects();
    this.recallWebhoookForSafe();
    this.safeCronJob();
    this.again();
    this.fetchSafeData();
    this.fetchBonus();

    // this.pageInfo = Api.getPaginatedData();
    // // console.log("Mounted data", this.pageInfo);
    // if (Object(this.pageInfo).length == 0) {
    //   this.pageInfo = Api.getPaginatedData();
    //   // console.log("Mounted data2", this.pageInfo);
    // }

    this.currentPath = this.$route.path;
    if (
      this.currentPath === "/dashboard/landlord" ||
      this.currentPath === "/dashboard/tenant"
    ) {
      this.currentPath = "Overview";
    } else if (this.currentPath === "/dashboard/properties") {
      this.currentPath = "Properties";
    } else {
      this.currentPath = "Wallet";
    }

    // this.fetchWalletDetails();
    this.getInviteList();

    // this.transactions = localStorage.getItem("page").data.transactions;
    // setTimeout(() => {
    //   this.transactions = JSON.parse(localStorage.getItem("page")).data.transactions;
    //   console.log("local statrage", this.transactions);
    // }, 4000);
  },
  methods: {
    // formatNumber() {
    //   const x = new Intl.NumberFormat().format(this.transferAmount);
    //   this.transferAmount = x;
    //   console.log(new Intl.NumberFormat().format(this.transferAmount));
    // },
    fundProjectModal: function() {
      console.log("Hello fund");
      alert("Open modal");
      //   $("#fundproject")
      //     .addClass("fade")
      //     .modal("show");
    },
    dataFromPaymentModal(data) {
      // console.log("Data from child", data);
      this.reference = data;
      // this.setupScheduleFinally();
    },
    navigateOption(x) {
      if (x === 2) {
        return this.$router.push({
          path: `/dashboard/autodebit`
        });
      }
      if (x === 3) {
        return this.$router.push({
          path: `/dashboard/safetransaction`
        });
      }
    },
    // getUserProfile() {
    //   Api.getRequest(`getuserbyid/${this.user.id}`)
    //     .then((res) => {
    //       console.log("User profile", res);
    //       this.user = res.data.user;
    //       localStorage.setItem("shelta-user", JSON.stringify(res.data.user));
    //       // console.log("countryid:", this.user.countryid);
    //     })
    //     .catch((err) => {
    //       this.loader = false;
    //       console.log(err);
    //     });
    // },

    paymentModalFund2: function() {
      // this.fundLoaderBlue
      if (this.blueSafeFundAmount === "") {
        return this.$store.commit("setApiFailed", "Enter amount");
      }
      const formattedAmount = this.blueSafeFundAmount.split(",").join("");
      const b = Number(formattedAmount) + Number(this.safeDetails.balance);
      if (b > 500000) {
        return this.$store.commit(
          "setApiFailed",
          "Estimated safe balance can't be more 500,000"
        );
      }
      // this.fundLoaderBlue = true;
      // console.log("Open payment");
      // this.paymentM = true;
      this.modalData.amount = 0;
      this.modalData.payableAmount = Number(formattedAmount);
      this.modalData.headerCaption = "Fund Your Blue SAFE";
      this.modalData.subtitle = "Fund Amount";
      this.modalData.paymentType = "fundbluesafe";
      this.modalData.headerBackground = "#0033ea";
      this.modalData.headerBackground = "#0033ea";
      // this.modalData.url = "";
      this.$root.$refs.A.openPaymentOptionsModal();
      $("#fundbluesafe")
        .removeClass("fade")
        .modal("hide");
    },

    async fundBlueSafe() {
      // this.fundLoaderBlue = true;
      // console.log("Reffffff:", this.reference);
      if (this.reference === "") {
        return this.$store.commit("setApiFailed", "No payment reference");
      }
      try {
        await Api.postRequest(`creditbluesafefrompaystack`, {
          reference: this.reference
        })
          .then(res => {
            // console.log("Fund blu safe response", res);
            this.fundLoaderBlue = false;
            if (res.data.success) {
              return this.$store.commit(
                "setApiSuccess",
                "SAFE has been funded"
              );
            }
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            console.log("err", err);
            this.fundLoaderBlue = false;
          });
      } finally {
        // console.log("closed");
      }
    },
    closedPaystack() {
      this.fundLoaderBlue = false;
      this.fundLoader = false;
      this.blueSafeFundAmount = "";
    },
    dataFromChild(data) {
      // this.pageData = dataFromChild;
      // console.log("Data from child", data);
      this.transactions = data.data.transactions;
    },
    serviceData() {
      this.pageInfo = Api.getPaginatedData();
      console.log("Page infooooooo", this.pageInfo);
      this.transactions = this.pageInfo.transactions;
    },
    again() {
      this.stateData = this.$store.state;
      // this.transactions = this.stateData.pageData;
      // console.log("Transactions from state page", this.transactions);
      // console.log("data from state page", this.stateData);
    },
    async fundProject() {
      this.fundLoader = true;
      if (this.projectId === "" || this.transferAmount === "") {
        this.fundLoader = false;
        return this.$store.commit("setApiFailed", "All fields are required");
      }
      const formattedAmount = this.transferAmount.split(",").join("");
      if (formattedAmount > this.safeDetails.balance) {
        this.fundLoader = false;
        return this.$store.commit(
          "setApiFailed",
          "Amount entered exceeds safe balance"
        );
      } else {
        try {
          await Api.postRequest(`creditprojectfromsafe`, {
            amount: parseFloat(formattedAmount),
            projectid: this.projectId
          })
            .then(res => {
              this.fundLoader = false;
              // console.log("Funds transfer succefully", res);
              if (res.data.success) {
                this.$store.commit("setApiSuccess", "Success");
                this.fetchSafeData();
                $("#fundproject")
                  .removeClass("fade")
                  .modal("hide");
              }
              if (res.data.error) {
                this.$store.commit("setApiFailed", res.data.error);
              }
            })
            .catch(err => {
              console.log("err", err);
              this.fundLoader = false;
            });
        } finally {
          console.log("close");
        }
      }
    },
    getProjects() {
      Api.getRequest(`getusersafeprojectsbyuserid/${this.user.id}`)
        .then(res => {
          // console.log("Projects", res);
          if (res.data.success) {
            this.projects = res.data.projects;
          }
          if (res.data.error) {
            this.$store.commit("setApiFailed", res.data.error);
          }
        })
        .catch(err => {
          console.log("err", err);
        });
    },
    paymentModalFund: function() {
      // console.log("Open payment");
      // this.paymentM = true;
      this.fundLoader = true;
      this.modalData.amount = 20000;
      this.modalData.paymentType = "fund project";
      this.modalData.headerBackground = "#0033ea";
      this.$root.$refs.A.openPaymentOptionsModal();
    },
    copyLink(value) {
      navigator.clipboard.writeText(value);
      return this.$store.commit("setApiSuccess", "Link copied to clipboard");
    },
    async fetchBonus() {
      try {
        await Api.getRequest(`safebonusdata`)
          .then(res => {
            // console.log("Bonus", res);
            this.bonusData = res.data;
          })
          .catch(err => {
            console.log("Error", err);
          });
      } finally {
        // console.log("closed");
      }
    },
    async getInviteList() {
      try {
        await Api.getRequest(`safeinvitelist/${this.user.id}`)
          .then(res => {
            // console.log("Invite list", res);
            if (res.data.success) {
              this.invites = res.data.safeinvitelist;
              this.yellow = this.invites.reduce(
                (total, color) => total + (color.color == "yellow"),
                0
              );
              this.red = this.invites.reduce(
                (total, color) => total + (color.color == "red"),
                0
              );
              this.green = this.invites.reduce(
                (total, color) => total + (color.color == "green"),
                0
              );
            }
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            console.log("Error", err);
          });
      } finally {
        // console.log("ok");
      }
    },
    recallWebhoookForSafe() {
      Api.getRequest(`requerysquadco`).then(res => {
        // this.fetchSafeData();
        // console.log("requry res", res);
      });
    },
    safeCronJob() {
      Api.postRequest(`cronjobforsafe`).then(res => {
        // this.fetchSafeData();
        // console.log("requry res", res);
      });
    },
    closeTC() {
      localStorage.removeItem("safeType");
      localStorage.removeItem("upgradeSafe");
    },
    upgradeSafe(type) {
      $("#safe_terms")
        .addClass("fade")
        .modal("show");
      localStorage.setItem("safeType", type);
      localStorage.setItem("upgradeSafe", "upgradeToGold");
      // return this.$store.commit("setApiWarning", "Please, we are updating this channel!");
    },
    selectSafeType(type) {
      // this.$store.dispatch("setSafe", type);
      localStorage.setItem("safeType", type);
    },
    navigateToActivateSafe() {
      this.$gtag.event("chose-type-of-safe-initial-click", {
        event_category: "dashboard documentaion clicks",
        event_label: "Chose Safe Type Button Clicked",
        value: 1
      });
      this.$router.push({
        path: `/dashboard/safeActivation`
      });
      $("#safe_terms")
        .removeClass("fade")
        .modal("hide");
    },
    fundFromBank: function(hi) {
      $("#fundme")
        .removeClass("fade")
        .modal("hide");
      $("#showpayment")
        .addClass("fade")
        .modal("show");
    },
    async getBankNames() {
      try {
        await Api.getRequest(`getbanks`)
          .then(res => {
            // console.log("Banks", res);
            this.banks = res.data.banks;
          })
          .catch(err => {
            console.log("Error", err);
          });
      } finally {
        console.log("closed connect");
      }
    },

    switchView(event) {
      if (event === 1) {
        document.getElementById("onee").style.display = "none";
        document.getElementById("twoo").style.display = "block";
        document.getElementById("threee").style.display = "none";
        this.headerText1 = false;
        this.headerText2 = true;
        this.headerText3 = false;
      } else if (event === 2) {
        document.getElementById("onee").style.display = "none";
        document.getElementById("twoo").style.display = "none";
        document.getElementById("threee").style.display = "block";
        this.headerText1 = false;
        this.headerText2 = false;
        this.headerText3 = true;
      } else if (event === 0) {
        document.getElementById("onee").style.display = "block";
        document.getElementById("twoo").style.display = "none";
        document.getElementById("threee").style.display = "none";
        // this.headerText2 = false;
      } else {
        document.getElementById("twoo").style.display = "none";
        document.getElementById("onee").style.display = "none";
        document.getElementById("threee").style.display = "block";
      }
    },

    selectFile(event) {
      const file = this.$refs.file.files[0];
      this.file = file;
      this.filePath = URL.createObjectURL(file);
      this.fileName = file.name;
      if (this.file) {
        this.fileselected = true;
      } else {
        this.fileselected = false;
      }
    },
    async sendFile() {
      // this.switchView(2)
      const file = this.$refs.file.files[0];
      // this.file = file;
      if (!this.file) {
        // this.loaderM = false;
        return this.$store.commit("setApiFailed", "No file was selected!");
      }
      const formData = new FormData();
      formData.append("file", this.file);
      try {
        this.uploading = true;
        await Api.postRequest(`paynow`, formData, {
          onUploadProgress: e =>
            (this.progress = Math.round((e.loaded * 100) / e.total))
        })
          .then(res => {
            this.$store.commit("setApiSuccess", "Success");
            this.switchView(2);
            this.uploading = false;
            if (res.data.success) {
              this.moreTenantData();
            }
          })
          .catch(err => {
            console.log(err);
            // this.loader = false;
            this.uploading = false;
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    openPanel: function(id) {
      // console.log("Index", id);
      this.panel = id;
      const coll = document.getElementsByClassName("collapsible");
      let i;

      for (i = 0; i < coll.length; i++) {
        coll[i].addEventListener("click", function() {
          // console.log("Index", coll);
          this.classList.toggle("active");
          const content = this.nextElementSibling;
          if (content.style.maxHeight) {
            // console.log("Next sibline", content);
            content.style.maxHeight = null;
          } else {
            content.style.maxHeight = content.scrollHeight + "px";
            // console.log("Next sibline2", content);
          }
        });
      }
    },
    // fetchWalletDetails: async function() {
    //   try {
    //     await Api.getRequest(`ewallet`)
    //       .then((res) => {
    //         // console.log("Wallet", res);
    //         this.wallet = res.data;
    //         // this.transactions = res.data.wallet;
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //         // this.loader = false;
    //         // this.uploading = true;
    //       });
    //   } finally {
    //     // console.log("Closed connection");
    //   }
    // },

    async fetchSafeData() {
      this.pageLoader = true;
      // console.log("are you safe", this.registedForSafe);
      try {
        await Api.getRequest(`fetchsafedata`)
          .then(res => {
            this.pageLoader = false;
            if (res.data.success) {
              // console.log("Safe data", res);
              this.safeDetails = res.data;
              if (res.data.safedata != null) {
                this.registedForSafe = true;
                // this.transactionList();
                // this.serviceData();
                // this.again();
                // this.transactions = this.$store.value.data.transactions;
                this.transactions = res.data.transactions;
                if (res.data.safedata.bvn != null) {
                  this.registedForSafe = true;
                  this.whichSafe = "yellowSafe";
                } else {
                  this.whichSafe = "blueSafe";
                  // this.registedForSafe = false;
                  this.registedForSafe = true;
                }
              } else {
                this.registedForSafe = false;
              }

              // if (res.data.safedata.bvn == null) {
              //   this.whichSafe = "blueSafe";
              //   // this.registedForSafe = false;
              //   this.registedForSafe = true;
              // }
            }
            if (res.data.error) {
              this.$store.commit("setApiFailed", "Can not read account");
            }
          })
          .catch(err => {
            this.pageLoader = false;
            console.log(err);
            if (err == "Error: Network Error") {
              this.registedForSafe = "";
            }
            // this.loader = false;
            // this.uploading = true;
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    transactionList() {
      Api.pushPageURL(`safetransactionspaginated`);
      // Api.getRequest(
      //   `safetransactionspaginated?page=${2}&perpage=${10}`
      // ).then(res => {
      //     console.log("Transactions list", res);
      //   })
      //   .catch(err => {
      //     console.log(err);
      //   });
    },
    bankTransferWithdrawal() {
      $("#bank_option")
        .removeClass("fade")
        .modal("hide");
      $("#agree")
        .addClass("fade")
        .modal("show");

      // if (this.percentageFull === true) {
      //   $("#showpayment").modal("show");
      // } else {
      //   $("#showpayment").modal("hide");
      //   return this.$store.commit(
      //     "setApiFailed",
      //     "Profile not completed"
      //   );
      // }
    },

    bankTransferOTP() {
      $("#agree")
        .removeClass("fade")
        .modal("hide");
      $("#withdraw_success")
        .addClass("fade")
        .modal("show");
    },
    concatOtp() {
      const theOtp = this.one.concat(this.two, this.three, this.four);
      this.token = theOtp;
      // this.sendOTP();
    },
    keytab(event) {
      const nextInput = event.srcElement.nextElementSibling; // get the sibling element
      const previousInput = event.srcElement.previousElementSibling;

      const target = event.target || event.srcElement;
      // const id = target.id;
      // console.log("Key pressed", event.key); // prints undefined
      if (event.key === "Backspace") {
        // console.log("back", target);
        // previousInput === null;
      }

      if (nextInput === null) {
        // check the maxLength from here
        // console.log("next check", nextInput);
        return;
      } else {
        nextInput.focus();
      } // focus if not null
    }
  },
  filters: {
    moment: function(date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$yellow: #ffb100;
$tatiary: #a2b0e3;
$black: #000000;
$limeGreen: #3ec250;

@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

// Lato fonts

@font-face {
  font-family: "Lato-Bold";
  src: local("Lato-Bold"),
    url("../assets/fonts/Lato/Lato-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"),
    url("../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-light";
  src: local("Lato-Light"),
    url("../assets/fonts/Lato/Lato-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-bold-Italic";
  src: local("Lato-BlackItalic"),
    url("../assets/fonts/Lato/Lato-BlackItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-thin-Italic";
  src: local("Lato-ThinItalic"),
    url("../assets/fonts/Lato/Lato-ThinItalic.ttf") format("truetype");
}

// Raleway font
@font-face {
  font-family: "Raleway-Bold";
  src: local("Raleway-Bold"),
    url("../assets/fonts/Raleway/static/Raleway-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Raleway";
  src: local("Raleway-Regular"),
    url("../assets/fonts/Raleway/static/Raleway-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Raleway-italic";
  src: local("Raleway-italic"),
    url("../assets/fonts/Raleway/static/Raleway-Italic.ttf") format("truetype");
}

// .small-top-nav {
//   background: $secondary;
// }
// .top-nav {
//   display: flex;
//   justify-content: space-between;
//   padding: 1rem;
//   height: 3.5rem;
//   p {
//     font-family: Gotham;
//     font-style: normal;
//     font-weight: 500;
//     font-size: 15px;
//     line-height: 30px;
//     align-items: center;
//     color: $black;
//   }
// }

.projects_link {
  h6 {
    background: #3059eb;
    color: $secondary;
    padding: 5px 10px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    border-radius: 4px;
    cursor: pointer;
    img {
      padding-right: 5px;
    }
  }
}

.your_wallet {
  img {
    background: #3059eb;
    border-radius: 3px;
    padding: 5px;
  }
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: $black;
  span {
    color: #1765ca;
    padding-left: 5px;
  }
}

.fund_btn {
  // position: absolute;
  // top: 10%;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 25px;
  padding-bottom: 60px;
  button:first-child {
    // border: 0.5px solid $secondary;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 30px;
    color: $secondary;
    border: 1px solid $secondary;
    // background: #3ec250;
    box-shadow: none;
    outline: 0;
    img {
      padding-left: 20px;
    }
  }
  button:nth-child(2) {
    // border: 0.5px solid $secondary;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 30px;
    border: 0.5px solid #ffffff;
    border-radius: 5px;
    color: $secondary;
    background: none;
    box-shadow: none;
    outline: 0;
    // img {
    //   padding-left: 20px;
    // }
  }
  button:hover {
    background: #2fc643;
    border: 0.5px solid $secondary;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
}

.roundedMenun {
  cursor: pointer;
  border: 1px solid $secondary;
  width: fit-content;
  border-radius: 50px;
  padding: 5px 10px;
  position: relative;
  .dot {
    height: 5px;
    width: 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: block;
    margin: 2px 0;
  }
}
.dot-menu {
  button {
    color: $black !important;
    font-size: 15px !important;
    font-weight: normal !important;
  }
  button:hover {
    background: #e9ecef !important;
    border-radius: 0 !important;
  }
}

.fund_title {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  color: $black;
  border: 1px solid #e5e5e5;
  padding-top: 10px;
  padding-bottom: 15px;
}

.funds_wrap {
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  margin: 40px 52px;
  border-radius: 10px;
}

.funds_options {
  margin: 10px 22px;
}

.fund_type2 {
  padding: 5px 15px;
  button {
    height: 40px;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 30px;
    box-sizing: border-box;
    display: flex;
    padding: 7px 25px;
    align-items: center;
    img {
      padding-right: 10px;
    }
  }
  .bank {
    background: $primary;
  }
  .paystack {
    background: #56c1f2;
  }
  .flutter {
    background: $yellow;
  }
  .enaira {
    background: #16780d;
    margin-bottom: 5px;
  }
}

.wallet {
  margin-top: 20px;
  height: 100%;
  padding-bottom: 1px;
  // height: 100vh;
}

.wallet_text_holder {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.activate_safe {
  margin: auto;
  button {
    position: relative;
    background: $limeGreen;
    color: $secondary;
    border: 1px solid $secondary;
    border-radius: 5px;
    width: 249.45px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    img {
      position: relative;
      right: -8%;
      width: 28px;
    }
  }
  button:hover {
    background: #43854c;
  }
}
.wallet_div {
  position: relative;
  background: linear-gradient(90deg, #ffb100 40.15%, #e8453c 173.98%);
  // background-image: url("../assets/images/gold-card-safe.svg");
  // background-size: 100% 100%;
  // background-attachment: fixed;
  background-repeat: no-repeat;
  // max-height: 248px;
  height: 100%;
  background-size: cover;
  // height: 100vh;
  border-radius: 10px;
  display: grid;
  // img {
  //   width: 100%;
  // }
}
.blueCard {
  position: relative;
  background: linear-gradient(
    90deg,
    #0434df 40.15%,
    rgba(0, 119, 255, 0.6) 173.98%
  );
  // background-image: url("../assets/images/blue-card-safe.svg");
  // background-size: 100% 100%;
  // background-attachment: fixed;
  background-repeat: no-repeat;
  // max-height: 248px;
  height: 100%;
  background-size: cover;
  // height: 100vh;
  border-radius: 10px;
  display: grid;
  // img {
  //   width: 100%;
  // }
}
.wallet_div > img {
  width: 100%;
}

.safe_header {
  // background: $primary;
  background: linear-gradient(90deg, #0434df 0%, #0077ff 100%);
  border: 1px solid #e5e5e5;
  border-radius: 10px 10px 0 0;
  padding-top: 35px;
  padding-bottom: 25px;
  padding-left: 30px;
  padding-right: 30px;
}

.safe_options {
  background: $secondary;
  border-radius: 0 0 10px 10px;
  padding: 30px;
}
.yellow_safe {
  background: $yellow;
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  padding: 25px;
  margin-bottom: 20px;
  p {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    align-items: center;
    color: $secondary;
  }
  button {
    margin-top: 35px;
    width: 100%;
    max-width: 249.45px;
    background: $secondary;
    color: $yellow;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    outline: 0;
    box-shadow: none;
  }
  .safe-rules {
    padding-left: 15px;
  }
}

.blue_safe {
  background: $primary;
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  padding: 25px;
  margin-bottom: 20px;
  p {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    align-items: center;
    color: $secondary;
  }
  button {
    margin-top: 35px;
    width: 100%;
    max-width: 249.45px;
    background: $secondary;
    color: $primary;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    outline: 0;
    box-shadow: none;
  }
}

.list-point {
  margin: auto;
  display: flex;
  justify-content: center;
}
ul.safe-rules {
  list-style-type: circle;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: $secondary;
  text-align: left;
}

.dream_start {
  h6 {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 100%;
    text-align: justify;
    color: $secondary;
    padding-bottom: 10px;
  }
  h1 {
    font-family: Raleway-italic;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 100%;
    text-align: justify;
    color: $secondary;
    span {
      color: $yellow;
    }
  }
}

.posibility_hints {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  color: $secondary;
  padding-top: 40px;
}

.safe_amount {
  display: flex;
  justify-content: space-between;
  // align-items: end;
  // align-items: baseline;
  // position: absolute;
  // top: 30%;
  // bottom: 20px;
  // right: 20px;
  // background-color: black;
  color: $secondary;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  max-height: 40px;

  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 14px;
    margin-bottom: 0;
    text-align: left;
  }
}

.eye1 {
  width: 40px;
  height: 40px;
  background: #e5a652;
  padding: 10px;
  border-radius: 5px;
}
.eye2 {
  width: 40px;
  height: 40px;
  background: #5b87e9;
  padding: 10px;
  border-radius: 5px;
}

.visibility_eye {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-top: 5px;
  small {
    font-family: Gotham;
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 20px;
    text-align: right;
  }
}

.amount-in-safe {
  // padding-top: 20px;
}

.uncleared_amount_yellow {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  color: $secondary;
}
.uncleared_text_yellow {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 30px;
  color: $secondary;
}

.uncleared_amount_blue {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  color: #ffb100;
}
.uncleared_text_blue {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 30px;
  color: #ffb100;
}

.uncleared_bonus {
  display: grid;
  span {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    // line-height: 30px;
    text-align: right;
    color: $secondary;
  }
  small {
    font-family: Lato;
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    // line-height: 30px;
    text-align: right;
    color: $secondary;
  }
}

.balance_text {
  position: absolute;
  bottom: 7%;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  display: flex;

  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 30px;
  color: $secondary;
}

.performance_index {
  // position: absolute;
  // bottom: 0;
  margin-top: 50px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: baseline;
  .index_number {
    visibility: hidden;
  }
  div:first-child {
    small {
      display: block;
      font-family: Lato;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 100%;
      color: $secondary;
      padding-top: 5px;
      padding-bottom: 10px;
    }
  }
}

// .radio-label {
//   font-family: Gotham;
//   font-style: normal;
//   font-weight: 900;
//   font-size: 12px;
//   line-height: 20px;
//   color: $secondary;
//   margin-right: 30px;
// }

.safe-account-number-holder {
  display: flex;
  justify-content: space-between;
  align-items: left;
  .safe-account-number {
    text-align: right;
    padding-right: 3px;
    // padding-top: 4px;
    padding-top: 20px;
    p {
      margin-bottom: 0;
      font-family: Gotham;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 10px;
      color: $secondary;
    }
    h6 {
      font-family: Gotham;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      // line-height: 30px;
      line-height: 24px;
      color: $secondary;
    }
  }
}

.upgradeSafeBtn {
  background: transparent;
  color: $secondary;
  font-family: Lato;
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 100%;
  border: 0.5px solid $secondary;
  border-radius: 5px;
  height: 32px;
  position: relative;
  bottom: -16px;
  outline: 0;
  box-shadow: none;
}

.bonus {
  background: linear-gradient(90deg, #56c1f2 40.15%, #0434df 173.98%);
  // background-image: url("../assets/images/bonus-card.svg");
  border-radius: 10px;
  background-size: cover;
  // max-height: 250px;
  height: 100%;
  h4 {
    display: flex;
    color: $secondary;
    padding: 20px 15px;
  }
}

.bonus_amount {
  display: flex;
  justify-content: space-between;
  align-items: left;
  div {
    padding-right: 20px;
  }
}

.bonus > div:first-child {
  display: flex;
  justify-content: space-between;
  // padding: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 25px;
  padding-bottom: 60px;
  align-items: self-start;
  p {
    color: $secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 30px;
  }
  button {
    background: transparent;
    border: 0.5px solid #ffffff;
    color: #fff;
    box-shadow: none;
    outline: 0;
  }
}

.widthdraw {
  display: flex;
  justify-content: space-between;
  padding: 20px 15px;
  padding-bottom: 0 !important;
  align-items: center;
  button {
    background: #7996fb;
    color: $secondary;
    border-radius: 5px;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 30px;
    padding: 5px 30px;
    outline: 0;
    box-shadow: none;
  }
  h6 {
    text-align: left;
    // padding-left: 45px;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 17px;
    color: $secondary;
    width: 52%;
  }
}

.fund {
  background: $secondary;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 10px;
}

.fund_wallet {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 10px 15px 0;
  border: 1px solid #e5e5e5;
  p {
    margin-bottom: 6px;
  }
}

.fund_type {
  padding: 5px 15px;
  button {
    height: 40px;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 30px;
    box-sizing: border-box;
    display: flex;
    padding: 7px 25px;
    align-items: center;
    img {
      padding-right: 10px;
    }
  }
  .bank {
    background: $primary;
  }
  .paystack {
    background: #56c1f2;
  }
  .flutter {
    background: $yellow;
  }
  .enaira {
    background: #16780d;
    margin-bottom: 5px;
  }
}

.recent_transactions {
  padding-top: 3rem;
  padding-bottom: 0.5rem;
  h5 {
    font-family: Gotham-bold;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    display: flex;
  }
}

.transaction_holder {
  cursor: pointer;
  background: $secondary;
  height: fit-content;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 10px;
  div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 15px;
    padding: 10px 15px;
    .data_type {
      padding-left: 5px;
      font-weight: 200;
      color: #2e2e2e;
    }
    // p:first-child {
    //   font-weight: bold;
    // }
    p {
      text-align: left;
      font-family: Gotham;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
      display: flex;
      align-items: center;
      color: $black;
      margin-bottom: 0;
    }
    h6 {
      text-align: left;
      font-family: Gotham-bold;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
      display: flex;
      align-items: center;
      color: $black;
      margin-bottom: 0;
    }
  }
}

.view-more {
  background: $secondary;
  background: transparent;
  color: $primary;
  // border: 1px solid $primary;
  font-family: Gotham;
  font-size: 15px;
  font-weight: normal;
  box-shadow: none;
  outline: 0;
  text-decoration: underline;
}

.collapsible {
  // background-color: #777;
  // color: white;
  // cursor: pointer;
  // padding: 18px;
  // width: 100%;
  // border: none;
  // text-align: left;
  // outline: none;
  // font-size: 15px;
}

// .active,
// .collapsible:hover {
//   background-color: #555;
// }

.collapsible:before {
  content: "\002B";
  color: $secondary;
  font-weight: bold;
  float: left;
  // margin-left: 5px;
  background: #2fc643;
  border-radius: 50px;
  height: 24px;
  width: 24px;
}

.active:before {
  content: "\2212";
}
.collapsible:after {
  content: "\02C7";
  font-size: 30px;
  // cotent" "\02C5";
  color: $black;
  font-weight: bold;
  float: left;
  height: 30px;
  // margin-left: 5px;
}

.active:after {
  content: "\02C6";
  //  content: "\02C4";
}

.content {
  padding: 0 18px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: #f1f1f1;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.content2 {
  padding: 0 18px !important;
  max-height: 0;
  overflow: hidden !important;
  transition: max-height 0.2s ease-out;
  background-color: #f1f1f1 !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px;
}

.input_side {
  padding: 15px 60px;
}
.close_bank {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  align-items: baseline;
  background: #efefef;
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: $black;
  }
  small {
    font-family: Gotham;
    padding-left: 1px;
    text-align: center;
    background: #c4c4c4;
    color: $black;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.closeFundProject {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  align-items: baseline;
  background: #efefef;
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 30px;
    color: $black;
  }
  small {
    font-family: Gotham;
    padding-left: 1px;
    text-align: center;
    background: #c4c4c4;
    color: $black;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.input_label {
  display: flex;
  font-family: Gotham;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  // line-height: 15px;
  color: $black;
}

.bank_input {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  line-height: 11px;
  color: #6c6c6c;
  box-sizing: border-box;
  border-radius: 5px;
  height: 45px;
  box-shadow: none;
  outline: 0;
}

.send_btn {
  padding: 20px 0;
  button {
    background: $primary;
    color: $secondary;
    height: 45px;
    border-radius: 5px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 17px;
  }
}

.opt-head {
  border-bottom: none;
}

.modal-body {
  .modal_veri_text {
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 23px;
    align-items: center;
    text-align: center;
    margin-bottom: 0;
    color: $black;
  }
  .enter_code_text {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 23px;
    align-items: center;
    text-align: center;
    color: $black;
    padding-bottom: 2rem;
  }
}

.otp-holder {
  display: flex;
  justify-content: space-evenly;
  input {
    width: 80px;
    border: none;
    border-bottom: 2px solid $primary;
    border-radius: 0;
    box-shadow: none;
  }
}

.otp__text {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  color: #28a745;
  font-weight: bold;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.otp__btn {
  padding: 3rem 10px;
  button {
    background: $primary;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    align-items: center;
    text-align: center;
    max-width: 377px;
    width: 100%;
    max-height: 45px;
    height: auto;
    box-shadow: none;
    margin-bottom: 2rem;
  }
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    align-items: center;
    text-align: center;
    color: $black;
    span {
      font-weight: bold;
      cursor: pointer;
    }
  }
}

.withdraw_image_wallet {
  margin-top: 50px;
}

.withdrawal_text_modal {
  p {
    padding: 20px 8px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: $black;
  }
}

.withdraw_footer {
  padding-bottom: 30px;
  p {
    margin-top: 10px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 20px;
    color: $primary;
  }
}

::-webkit-input-placeholder {
  /* Edge */
  font-family: Gotham;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 11px;
  color: #6c6c6c;
}

:-ms-input-placeholder {
  /* Internet Explorer */
  font-family: Gotham;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 11px;
  color: #6c6c6c;
}

::placeholder {
  font-family: Gotham;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 11px;
  color: #6c6c6c;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// SAFE terms and condition starts
.modal_safe_activation_text {
  background: $primary !important;
  h6 {
    color: $secondary;
  }
  small {
    background: $secondary;
    color: #ea0000;
  }
}
.safe_terms_content {
  margin: 20px;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
}
.terms_condition {
  overflow-y: auto;
  max-height: 400px;

  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 22px;
    text-align: justify;
    color: $black;
  }
  h5 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    color: $black;
    line-height: 14px;
    text-align: left;
  }
  .side_bold {
    font-weight: bold;
    font-size: 12px;
  }
  // h6 {

  // }
  ul.benefits {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 22px;
    //  margin-bottom: 0;
    text-align: left;
    color: $black;
    list-style-type: circle;
  }
}
.checkbox_footer {
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: -16px;
  color: $black;
  // text-align: center;
  /* margin: 0; */
  background: rgba(4, 52, 223, 0.1);
  /* width: 100%; */
  // height: 39.51px;
  padding: 10px 20px;
  border-radius: 0 0px 10px 10px;
  text-align: left;
  label {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    // line-height: 18px;
  }
}
// SAFE terms and condtion ends

// Invite list ends

.invit-text-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.color-dots-holder {
  display: flex;
  span {
    padding: 0px 10px 0 3px;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: $black;
  }
}
.green-dot {
  width: 1rem;
  height: 1rem;
  border-radius: 100px;
  background: #2fc643;
  margin: auto;
  margin-top: 3px;
}
.yellow-dot {
  width: 1rem;
  height: 1rem;
  border-radius: 100px;
  background: #ffb100;
  margin: auto;
  margin-top: 3px;
}
.red-dot {
  width: 1rem;
  height: 1rem;
  border-radius: 100px;
  background: #ea0000;
  margin: auto;
  margin-top: 3px;
}

.invite-list {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  color: $black;
  text-align: left;
}
.table-wrap {
  background: $secondary;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 40px;
}
.seperate_border {
  border-collapse: separate;
  border-spacing: 0px 15px;
}

.seperate_border {
  th,
  td {
    border-spacing: 0 15px;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    text-align: left;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    // line-height: 30px;
    color: #6c6c6c;
    white-space: nowrap;
  }
  th:first-child {
    border-left: 1px solid #e5e5e5;
    border-radius: 10px 0 0 10px;
  }
  th:last-child {
    border-right: 1px solid #e5e5e5;
    border-radius: 0px 10px 10px 0px;
    font-weight: bold !important;
    color: $black !important;
  }
}

.copyBtn {
  background: $secondary;
  color: $primary;
  border: 1px solid $primary;
  font-size: 10px;
  font-weight: bold;
  font-family: Lato;
  box-shadow: none;
  outline: 0;
}
// Invite List ends

// Fund project modal begins
.fundmodalContent {
  border-radius: 20px;
  input,
  select {
    border: 1px solid #d2d3d3;
    border-radius: 6px;
    height: 45px;
    box-shadow: none;
    outline: 0;
    // color: #b1b2b2;
    color: $black;
    font-size: 15px;
    margin-bottom: 25px;
    font-family: Gotham;
  }
}

.transferSafeBalance {
  font-family: Gotham;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #262626;
  text-align: left;
  padding-top: 10px;
  span {
    color: $primary;
  }
}

.transfer-btn {
  button {
    box-shadow: none;
    outline: 0;
    background: $primary;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    height: 45px;
  }
}

// Fund project modal ends

@media only screen and (max-width: 599px) {
  // .select-safe-row {
  //   flex-wrap: nowrap;
  // }
  .amount-in-safe {
    padding-top: 20px;
  }
  .posibility_hints {
    padding-top: 10px;
  }
  .safe_options {
    padding: 30px 0;
  }
  .safe_amount {
    // align-items: center;
    align-items: baseline;
    top: 46%;
    p {
      font-size: 22px;
    }
    .safe-logo {
      width: 40px;
    }
  }
  .widthdraw {
    padding: 45px 15px;
    h6 {
      font-size: 12px;
    }
  }
  .bonus {
    h4 {
      padding: 0px 15px;
      button {
        padding: 5px 30px;
      }
    }
  }
  // .row_holder {
  //   div:first-child {
  //     margin-bottom: 10px;
  //   }
  // }
  .bonus {
    margin-top: 10px;
  }
  .transaction_holder {
    div:first-child {
      gap: 10px;
      p {
        line-height: 18px;
      }
    }
  }
  .performance_index {
    align-items: end;
  }
  .fund_btn {
    padding-bottom: 30px;
  }
  .upgradeSafeBtn {
    bottom: 10px;
  }
  .collapsible:after {
    content: none;
  }
  .collapsible:before {
    height: 24px;
    width: 24px;
    padding-right: 8px;
    padding-top: 1px;
    padding-left: 8px;
  }
  .otp-holder {
    input {
      width: 40px;
    }
  }
  .funds_wrap {
    margin: 40px 0px;
  }
  .funds_options {
    margin: 10px 0px;
  }
  .withdrawal_text_modal {
    p {
      font-size: 11px;
    }
  }
  .transaction_holder {
    div:first-child {
      overflow: scroll;
      margin-right: 5px;
      p {
        white-space: nowrap;
        border-right: 5px solid blue;
        padding-right: 10px;
        font-size: 14px;
      }
    }
  }

  // Bank transfer
  .info__text {
    text-align: justify;
  }
  .number__holder {
    p:nth-child(2) {
      line-height: 23px;
      text-align: left;
      font-size: 13px;
    }
  }
  .sheltaAccount__details {
    div {
      margin: 5px 15px;
      p:first-child {
        font-size: 13px;
      }
      p:nth-child(2) {
        font-size: 13px;
      }
    }
  }
  .second__number {
    padding-top: 2rem;
  }
  .desclaimer__text {
    // padding-top: 1rem;
    p {
      font-size: 13px;
      line-height: 25px;
      text-align: left;
    }
  }
  .payment-header {
    font-size: 15px;
  }
  .third-step {
    p {
      font-size: 13px;
      line-height: 24px;
      text-align: left;
      padding-top: 2rem;
    }
  }
  .call-btn {
    font-size: 13px;
    padding: 6px 34px;
    a {
      img {
        margin-right: 2rem;
        width: 20.01px;
      }
    }
  }
  .third-step {
    img {
      width: 7rem;
    }
  }
  // Bank transfer
}
</style>
